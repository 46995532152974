import memoize from '@github/memoize'
import {hasMatch} from 'fzy.js'
import type {
  SearchProvider,
  FilterProvider,
  QueryEvent,
  QueryFilterElement,
} from '@github-ui/query-builder-element/query-builder-api'
import {FilterItem, SearchItem, Octicon} from '@github-ui/query-builder-element/query-builder-api'
import type {QueryBuilderElement} from '@github-ui/query-builder-element'

interface Suggestion {
  login: string
}

interface PublisherSuggestions extends Suggestion {
  apps: Suggestion[]
  actions: Suggestion[]
  stacks: Suggestion[]
  all: Suggestion[]
}

async function fetchJSON(url: string) {
  const response = await fetch(url, {headers: {Accept: 'application/json'}})

  if (response.ok) {
    return await response.json()
  } else {
    return undefined
  }
}
const memoizeCache = new Map()
const memoizeFetchJSON = memoize(fetchJSON, {cache: memoizeCache})

const searchInputId = 'query-builder-marketplace-search-box'

function getSearchInput(): HTMLElement {
  return document.querySelector<HTMLElement>(`query-builder#${searchInputId}`)!
}

async function fetchPublisherSuggestions(): Promise<PublisherSuggestions> {
  const url = getSearchInput().getAttribute('data-suggestable-publishers-path')!
  return memoizeFetchJSON(url)
}

async function publishers(): Promise<Suggestion[]> {
  const results = await fetchPublisherSuggestions()

  const toolInput = document.getElementById('type') as HTMLInputElement
  const toolTypeValue = toolInput?.value

  const entities =
    toolTypeValue === 'apps'
      ? results.apps
      : toolTypeValue === 'actions'
        ? results.actions
        : toolTypeValue === 'stacks'
          ? results.stacks
          : results.all

  return entities
}
const sortStates = [
  {name: 'Best Match', value: 'match-desc'},
  {name: 'Recently added', value: 'created-desc'},
  {name: 'Popularity', value: 'popularity-desc'},
]

const getSearchUrl =
  document.querySelector<HTMLFormElement>('#marketplace-search-combobox-form')?.getAttribute('action') || ''

const filterSuggestions = (suggestions: string[], query: string) =>
  suggestions.filter(suggestion => {
    if ((query && hasMatch(query, suggestion)) || query === '') return suggestion
  })

class SearchItemProvider extends EventTarget implements SearchProvider {
  priority = 1
  name = 'Search'
  singularItemName = 'search item'
  value = 'search'
  type = 'search' as const

  declare queryBuilder: QueryBuilderElement

  constructor(queryBuilder: QueryBuilderElement) {
    super()

    this.queryBuilder = queryBuilder
    this.queryBuilder.addEventListener('query', this)
    this.queryBuilder.attachProvider(this)
  }

  handleEvent(event: QueryEvent) {
    if (String(event) === '') return
    this.dispatchEvent(
      new SearchItem({
        priority: 1,
        value: event.toString(),
        icon: Octicon.Search,
        scope: 'GENERAL',
        action: {
          url: `${getSearchUrl}?query=${event.toString()}`,
        },
      }),
    )
  }
}

class PublisherProvider extends EventTarget implements FilterProvider {
  name = 'Publishers'
  singularItemName = 'publisher'
  value = 'publisher'
  priority = 2
  type = 'filter' as const

  declare queryBuilder: QueryBuilderElement

  constructor(queryBuilder: QueryBuilderElement) {
    super()

    this.queryBuilder = queryBuilder
    this.queryBuilder.addEventListener('query', this)
    this.queryBuilder.attachProvider(this)
  }

  async handleEvent(event: QueryEvent) {
    const lastQuery = event.parsedQuery.at(-1)
    const lastQueryValue = lastQuery?.value || ''
    const lastQueryType = lastQuery?.type
    const lastQueryFilter = (lastQuery as QueryFilterElement)?.filter || ''

    if (lastQueryType !== 'filter' && (hasMatch(lastQueryValue, this.value) || lastQueryValue === '')) {
      this.dispatchEvent(new Event('show'))
    }

    // Prevents the publisher filters from being fetched if the publisher filter was not chosen
    if (lastQueryType !== 'filter' || lastQueryFilter !== this.value) return

    const getPublisherData = await publishers()
    const publisherValues = getPublisherData.map(publisher => publisher.login)
    const filteredPublishers = filterSuggestions(publisherValues, lastQueryValue)

    // Limit to top 30 publishers
    const limitedFilteredPublisher = filteredPublishers.slice(0, 30)

    for (const publisher of limitedFilteredPublisher) {
      this.dispatchEvent(new FilterItem({filter: 'publisher', value: publisher}))
    }
  }
}

class SortProvider extends EventTarget implements FilterProvider {
  name = 'Sort by'
  singularItemName = 'sort'
  value = 'sort'
  priority = 3
  type = 'filter' as const

  declare queryBuilder: QueryBuilderElement

  constructor(queryBuilder: QueryBuilderElement) {
    super()

    this.queryBuilder = queryBuilder
    this.queryBuilder.addEventListener('query', this)
    this.queryBuilder.attachProvider(this)
  }

  handleEvent(event: QueryEvent) {
    const lastQuery = event.parsedQuery.at(-1)
    const lastQueryValue = lastQuery?.value || ''
    const lastQueryType = lastQuery?.type
    const lastQueryFilter = (lastQuery as QueryFilterElement)?.filter || ''

    const filteredStates = filterSuggestions(
      sortStates.map(state => state.name),
      lastQueryValue,
    )

    if (lastQueryType !== 'filter' && (hasMatch(lastQueryValue, this.value) || lastQueryValue === '')) {
      this.dispatchEvent(new Event('show'))
    }

    if (lastQueryType !== 'filter' || lastQueryFilter !== this.value) return

    for (const state of filteredStates) {
      const index = sortStates.findIndex(item => item.name === state)
      const value = sortStates[index]?.value || ''
      this.dispatchEvent(new FilterItem({filter: 'sort', name: state, value}))
    }
  }
}

document.addEventListener('query-builder:request-provider', (event: Event) => {
  const target: QueryBuilderElement | null = event.target as QueryBuilderElement
  if (!target || target.id !== searchInputId) return

  new SearchItemProvider(event.target as QueryBuilderElement)
  new PublisherProvider(event.target as QueryBuilderElement)
  new SortProvider(event.target as QueryBuilderElement)
})
